import React, { Component } from "react";
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Nav from "./Nav";
import Home from "./Home";
import PortfolioListing  from "./PortfolioListing";
import Contact from "./Contact";
import Footer from "./Footer";

import logo from "../imgs/graybear.svg";

import devdesignData from "../json/devdesign.json";
import uxuiData from "../json/uxui.json";

function menuScroll(className) {

    console.log("Menu Scroll!");

    setTimeout(
        function() {
            document.querySelector(`.${className}`).scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
        .bind(this),
        300
    );
}

class App extends Component {

    constructor() {

        super();

        this.contactPressed = this.contactPressed.bind(this);
        this.educationPressed = this.educationPressed.bind(this);       
        this.homePressed = this.homePressed.bind(this);
        this.portfolioPressed = this.portfolioPressed.bind(this);
        this.portfolioDevDesignPressed = this.portfolioDevDesignPressed.bind(this);
        this.portfolioUXUIPressed = this.portfolioUXUIPressed.bind(this);
        this.resumePressed = this.resumePressed.bind(this);
        this.scrollTop = this.scrollTop.bind(this);
        this.skillsPressed = this.skillsPressed.bind(this);
        this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
    
        this.state = {
            isContact: false,
            isHome: true,
            isPortfolio: false,
            isPortfolioDevDesign: false,
            isPortfolioUXUI: false,
            showMobileMenu: false
        };
    }

    contactPressed() {
        console.log("Contact pressed!");
        this.scrollTop();
        this.setState({
            isContact: true,
            isHome: false,
            isPortfolio: false,
            isPortfolioDevDesign: false,
            isPortfolioUXUI: false,
            showMobileMenu: false
        });
    }

    educationPressed() {
        console.log("Education pressed!");
        this.setHome();

        menuScroll("education");
    }

    homePressed() {
        console.log("Home pressed!");
        this.setHome();
    }

    portfolioPressed() {
        console.log("Portfolio pressed!");
        this.setHome();
        menuScroll("portfolio");
    }

    portfolioDevDesignPressed() {
        console.log("portfolioDevDesign pressed!");
        this.scrollTop();
        this.setState({
            isContact: false,
            isHome: false,
            isPortfolio: true,
            isPortfolioDevDesign: true,
            isPortfolioUXUI: false,
            projectsData: devdesignData.devdesign,
            showMobileMenu: false
        });
    }

    portfolioUXUIPressed() {
        console.log("portfolioUXUI pressed!");
        this.scrollTop();
        this.setState({
            isContact: false,
            isHome: false,
            isPortfolio: true,
            isPortfolioDevDesign: false,
            isPortfolioUXUI: true,
            projectsData: uxuiData.uxui,
            showMobileMenu: false
        });
    }

    resumePressed() {
        console.log("Resume pressed!");
        this.setHome();
        menuScroll("resume");
    }

    setHome() {
        console.log("Set Home!");
        this.scrollTop();
        this.setState({
            isContact: false,
            isHome: true,
            isPortfolio: false,
            isPortfolioDevDesign: false,
            isPortfolioUXUI: false,
            showMobileMenu: false
        });

        menuScroll("App");
    }


    scrollTop() {
        window.scrollTo(0, 0);
    }

    skillsPressed () {
        console.log("Skills pressed!");
        this.setHome();
        menuScroll("skills");
    }

    toggleMobileMenu() {
        this.state.showMobileMenu ? 
            this.setState({showMobileMenu: false})
            :
            this.setState({showMobileMenu: true})
    }

    render() {
        return (
            <div className="App">
                
                <header>
                    <div className="inner-header">

                        <img
                            onClick={this.homePressed} 
                            src={logo} alt="Graybear LLC"/>

                        <div className={classNames("container", "desktop")}>
                            <Nav
                                className="headerNav"
                                contactPressed={this.contactPressed}
                                educationPressed={this.educationPressed}
                                homePressed={this.homePressed}
                                portfolioPressed={this.portfolioPressed}
                                resumePressed={this.resumePressed}
                                skillsPressed={this.skillsPressed}
                            />
                        </div>

                    </div>

                    <div 
                        className={
                            classNames("mobile", "mobileMenuButton")}
                        onClick={this.toggleMobileMenu}
                    >
                        {this.state.showMobileMenu ? "< close >" : "< menu >"}
                    </div>

                    <div className={classNames("mobile", "mobileMenu",
                        `${this.state.showMobileMenu ? "active" : null}`
                    )}>
                        <Nav
                            className="headerNav"
                            contactPressed={this.contactPressed}
                            educationPressed={this.educationPressed}
                            homePressed={this.homePressed}
                            portfolioPressed={this.portfolioPressed}
                            resumePressed={this.resumePressed}
                            skillsPressed={this.skillsPressed}
                        />
                    </div>

                    <svg className="svg-curve" viewBox="0 0 1440 79" xmlns="http://www.w3.org/2000/svg">
                        <path d="M-100 79C-100 79 218.416 23.165 693.5 23.165C1168.58 23.165 1487 79 1487 79V0H-100V79Z"></path>
                    </svg>
                </header>

                <div className="container">

                    <Home
                        className="home"
                        portfolioDevDesignPressed={this.portfolioDevDesignPressed}
                        portfolioUXUIPressed={this.portfolioUXUIPressed}
                        showHide={this.state.isHome ? "show" : "hide"}
                    />

                    {this.state.isPortfolio ?
                        <PortfolioListing
                            className={
                                this.state.isPortfolioDevDesign ? "portfolioDevDesign" : "portfolioUXUI"
                            }
                            isPortfolioDevDesign={this.state.isPortfolioDevDesign}
                            projectsData={this.state.projectsData}
                            scrollTop={this.scrollTop}
                            showHide={this.state.isPortfolio ? "show" : "hide"}
                        />
                        :
                        null
                    }

                    <Contact
                        className="contact"
                        showHide={this.state.isContact ? "show" : "hide"}
                    />

                    <Footer
                        className="footer"
                    />
                    
                </div>
            </div>
        );
    }
}

export default App;